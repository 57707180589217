
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  inheritAttrs: false
})
export default class Template extends Vue {
  @Prop({ default: false }) enable: Boolean;
  @Prop({ default: () => ({}) }) rowData: any;
  @Prop() updateMethod: any;
  changeStatus: boolean = false;
  confirmUpdateStatus(status) {
    this.updateMethod(status, this.rowData);
  }
  switchChange(status) {
    this.changeStatus = status;
  }
  switchChangeSure() {
    this.confirmUpdateStatus(Number(this.changeStatus));
  }
  switchEnable() {
    this.confirmUpdateStatus(1);
  }
}
