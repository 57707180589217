
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

@Component({})
export default class StepList extends Vue {
  @Prop({ default: false, type: Boolean })
  showFlag: boolean;
  @Prop({ default: "标" })
  text: string;
  @Prop({ default: "系统标准数据，不可修改" })
  tipsText: string;
}
